import { getCurrentDate, getStartDateOfCurrentMonth } from '../../../utils/date';
import { selectObj } from '../../utils/air';
import { momentObject } from '../../utils/formatDate';

import ITEMSONPAGE from '../../../constants/pagination';
import {
  FIELDS,
  INVALID_CONTRACT,
} from '../../constants/profile';
import { REQUISITES_SMARTWAY } from '../../constants/reqisites';
import { AIRLINE_PROVIDERS } from '../../constants/airline';
import { BONUSES_TYPE } from '../../constants/bonuses';


import {
  createNewRate,
  deafultPeriodRate,
  defaultHotelAutocomplete,
  createNewValidationRate,
  createDefaultValidation,
  defaultActiveHotelContract,
  defaultActiveAviaContract,
  createDefaultValidationAviaContract,
} from './defaultState';

let ACTIONS;

const STATUS_RU = {
  Booked: 'Забронирована',
  AwaitingConfirmation: 'Ожидает подтверждения',
  Cancelled: 'Отменена',
  Completed: 'Завершена',
  AwaitingTicketing: 'Ожидает бронирования',
};

const DEFAULTLIMITAMOUNT = {
  enabled: false,
  amount: null,
};

const DEFAULT = {
  id: null,
  accountTypeList: [],
  companySettings: {},
  startDate: getStartDateOfCurrentMonth(),
  endDate: getCurrentDate(),
  maxDate: getCurrentDate(),
  loadingButton: true,
  ShowInDocuments: false,
  cardPaymentFee: true,
  formedRevise: false,
  userCompanies: [],
  processedFile: {
    secondPhase: false,
    loadDocument: false,
    loading: false,
    information: {
      allEmployeesCount: null,
      newEmployeesCount: null,
      updateEmployeesCount: null,
      unchangeableEmployeesCount: null,
      errorEmployeesCount: null,
      successUploadCount: null,
      errorUploadCount: null,
      errors: '',
      fileLink: '',
      updateGuid: {},
    },
  },
  file: {
    uploaded: false,
    failed: false,
    error: '',
    guid: '',
  },
  profile: {
    details: {},
    schemas: {},
    periodUpd: null,
    aviaContracts: [],
    hotelContracts: [],
    users: [],
  },
  loading: true,
  userSettings: {},
  prohibitionVouchers: [],
  applications1CFF: null,
  aviaContractData: {
    activeAviaContract: {},
    showAviaContractDialog: false,
    showErrorSaveAviaContractDialog: false,
    showErrorDeleteAviaContractDialog: false,
    loading: false,
    validation: createDefaultValidationAviaContract(),
  },
  userActiveData: {
    userApproveScheme: [],
    deleteDialog: {
      show: false,
      loading: false,
      error: false,
    },
    showEditUserDialog: false,
    showUserErrorDialog: false,
    showUserRightsDialog: false,
    showUserApproveDialog: false,
    saveErrorSetRightUser: false,
    showNextTapTransferOfRightsDialog: false,
    showNextStepDisableOfRightsDialog: false,
    sendUserDocFail: false,
    waitingSendUserDoc: false,
    loadingButtons: {},
    loading: false,
  },
  hotelContractData: {
    activeHotelContract: {},
    ratesAanda: [],
    validation: createDefaultValidation(),
    hotelNameAutocomplete: defaultHotelAutocomplete(),
    roomTypeIds: [],
    showHotelContractDialog: false,
    showErrorDialog: false,
    showErrorHotelContractDialog: false,
    loading: false,
    uploadedFile: false,
    failedFile: false,
    processing: false,
  },
  employees: [],
  archivedEmployees: [],
  finance: {
    waitigResponseForApprove: false,
    transactions: [],
    errorFlag: false,
    IsPlanFee: false,
    IsBankPayment: false,
    IsCardPaymentOrTransactionFee: false,
    filterTransactions: [],
    pagination: {
      visibleTransactions: [],
      itemsOnPage: ITEMSONPAGE.OPT3,
      currentPage: 1,
    },
    debitOrCreditDialogState: {
      date: '',
      amount: '',
      description: '',
    },
    tableState: {
      transaction: {
        CompanyId: null,
        Credit: null,
        Debit: null,
        Description: '',
        EventDate: '',
        Id: null,
        InvoiceNum: '',
        IsPlanFee: false,
        Type: null,
      },
    },
    editTransactionDialogState: {
      date: '',
      amount: '',
      credit: '',
      description: '',
      type: null,
    },
    validation: {
      debitOrCreditDialog: {
        date: '',
        amount: '',
        credit: '',
        description: '',
      },
      editTransactionDialog: {
        amount: '',
      },
    },
  },
  commentCompany: null,
  closingFio: {
    companyId: null,
    schemaName: '',
    closingsWithoutFio: false,
    disable: true,
  },
  accounting: {
    details: {},
    schemas: {},
    periodUpd: null,
    balance: {},
    billing: {},
    funds: {},
    operations: [],
    integration: null,
    periodEdo: 'None',
    taxShemasSA: 0,
    edo: '',
    eventDate: '',
    userName: '',
    limitAmount: DEFAULTLIMITAMOUNT,
    errorExpenseReportDialog: '',
    loadingAccountins: true,
  },
  documents: {
    packages: [],
    documentpackageDetails: [],
    documentpackageChanges: [],
    selectedPackages: {},
    pagination: {
      visiblePackages: [],
      itemsOnPage: ITEMSONPAGE.OPT1,
      currentPage: 1,
    },
    requests: {
      requestDiadoc: { isRequest: false, msg: '' },
    },
  },
  trips: [],
  debts: {
    isLoading: true,
    data: {},
  },
  insuranceData: {
    ActiveInsurance: { },
    HistoryInsurances: [],
  },
  companyDebt: null,
  requisites: REQUISITES_SMARTWAY,
  packageDetails: [],
  packageChanges: [],
  documentsCorrection: [],
  loadingCompanySettings: true,
  dates: {
    startDate: getStartDateOfCurrentMonth(),
    endDate: getCurrentDate(),
  },
  expenseReports: {
    isLoading: true,
    deleteItemLoading: false,
    data: null,
  },
};

const foundIataIndex = (list, iata) => list.findIndex(({ Iata }) => Iata === iata);
const finderIata = aviaContract => selectObj(aviaContract).find(({ iata }) => iata === aviaContract.Iata);
const nameIata = aviaContract => (finderIata(aviaContract) ? finderIata(aviaContract).cost : aviaContract.Iata);
const preparedAviaCodeNumber = contract => (contract.Iata === 'SU' && contract.IsDiscount ? `CK${contract.Number}` : contract.Number);
const detectUsersWithFF = (applications1CFF, flagName) => {
  if (Object.keys(applications1CFF).length === 0) return null;

  let userWith1CFF = null;

  applications1CFF.forEach(({ Email, Flags }) => {
    if (flagName) {
      userWith1CFF = { ...userWith1CFF, [Email]: Flags.Salary1C };
    }
  });

  return userWith1CFF;
};

const preparedDate = date => (
  date ? momentObject(date) : null
);

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.COMPANY.SETID: {
      return {
        ...state,
        id: action.id,
      };
    }
    case ACTIONS.COMPANY.LOADPROFILE: {
      const { Contract } = action.profile.details;
      const aviaContracts = action.profile.aviaContracts ? action.profile.aviaContracts : [];
      const mapperAviaContracts = aviaContracts.map(item => ({
        ...item,
        Iata: item.Iata ? nameIata(item) : '',
        IsDiscount: Number(item.IsDiscount),
        Number: preparedAviaCodeNumber(item),
        Type: item.Iata === AIRLINE_PROVIDERS.S7
          ? BONUSES_TYPE.MILES
          : item.Type,
      }));

      return {
        ...state,
        profile: {
          ...action.profile,
          aviaContracts: mapperAviaContracts,
          details: {
            ...action.profile.details,
            Contract: {
              Number: Contract ? Contract.Number : '',
              StartDate: Contract && Contract.StartDate ? momentObject(Contract.StartDate) : '',
            },
          },
        },
        applications1CFF: detectUsersWithFF(action.applications1CFF, 'Flags.1CSalary'),
      };
    }

    case ACTIONS.COMPANY.LOADEMPLOYEES: {
      return {
        ...state,
        employees: action.employees,
        archivedEmployees: action.archivedEmployees,
      };
    }

    case ACTIONS.COMPANY.LOAD_ACCOUNT_TYPES: {
      const accountTypesMapper = Object.entries(action.payload).map(el => ({ value: el[0], label: el[1] }));

      return {
        ...state,
        accountTypeList: accountTypesMapper,
      };
    }

    case ACTIONS.COMPANY.LOADFINANCE: {
      return {
        ...state,
        finance: {
          ...state.finance,
          transactions: action.transactions,
          pagination: action.pagination,
        },
      };
    }

    case ACTIONS.COMPANY.DELETE_TRANSACTION: {
      const { errorFlag } = action;
      return {
        ...state,
        finance: {
          ...state.finance,
          errorFlag,
        },
      };
    }

    case ACTIONS.COMPANY.SET_COMMENT_COMPANY: {
      return {
        ...state,
        commentCompany: {
          ...state.commentCompany,
          text: action.payload,
        },
      };
    }

    case ACTIONS.COMPANY.SET_SHEMAS_TAX_SA: {
      return {
        ...state,
        accounting: {
          ...state.accounting,
          taxShemasSA: action.payload,
        },
      };
    }

    case ACTIONS.COMPANY.DISABLED_FIO_SCHEMAS_CLOSING: {
      return {
        ...state,
        closingFio: {
          ...state.closingFio,
          closingsWithoutFio: false,
          disable: action.disable,
        },
      };
    }

    case ACTIONS.COMPANY.EDIT_FIO_SCHEMAS_CLOSING: {
      return {
        ...state,
        closingFio: {
          ...state.closingFio,
          closingsWithoutFio: action.value,
        },
      };
    }

    case ACTIONS.COMPANY.LOAD_FIO_SCHEMAS_CLOSING: {
      const { payload } = action;

      const newState = {
        ...state,
        closingFio: payload,
      };

      return newState;
    }

    case ACTIONS.COMPANY.GET_COMMENT_COMPANY: {
      const { commentCompany } = action;

      const newState = {
        ...state,
        commentCompany,
      };

      return newState;
    }

    case ACTIONS.COMPANY.GET_SHEMAS_TAX_SA: {
      return {
        ...state,
        accounting: {
          ...state.accounting,
          taxShemasSA: action.taxShemasSA,
        },
      };
    }

    case ACTIONS.COMPANY.LOADACCOUNTING: {
      const { operations, ...accounting } = action.accounting;
      const newState = {
        ...state,
        accounting,
      };

      newState.accounting.reports = operations;
      newState.accounting.balance = operations.reduce((acc, item) => ({
        Credit: acc.Credit + item.Debit, // yes, Credit it's Debit...
        Debit: acc.Debit + item.Credit,  // white it's black, life it's death
      }), { Credit: 0, Debit: 0 });

      if (accounting.packageEdo.length) {
        newState.accounting.edo = accounting.packageEdo[0].EDO;
        newState.accounting.periodEdo = accounting.packageEdo[0].SendingPeriod;
        newState.accounting.eventDate = accounting.packageEdo[0].EventDate;
        newState.accounting.userName = accounting.packageEdo[0].UserName;
      }

      if (!accounting.packageEdo.length) {
        newState.accounting.edo = DEFAULT.accounting.edo;
        newState.accounting.periodEdo = DEFAULT.accounting.periodEdo;
        newState.accounting.eventDate = DEFAULT.accounting.eventDate;
        newState.accounting.userName = DEFAULT.accounting.userName;
      }

      return newState;
    }

    case ACTIONS.COMPANY.LOADDOCUMENTS: {
      const newState = {
        ...state,
        documents: {
          ...state.documents,
          packages: action.packages,
          selectedPackages: {},
          pagination: action.pagination,
        },
        packageDetails: action.documentpackageDetails.filter(item => !!item),
        packageChanges: action.documentpackageChanges.filter(item => !!item),
      };
      newState.documents.packages.forEach((item) => { newState.documents.selectedPackages[item.Id] = false; });

      return newState;
    }

    case ACTIONS.COMPANY.LOADTRIPS:
    case ACTIONS.COMPANY.UPDATETRIPS: {
      return {
        ...state,
        trips: action.trips.map(item => ({
          ...item,
          StatusRu: STATUS_RU[item.Status],
        })),
      };
    }

    case ACTIONS.COMPANY.LOADPACKAGEDETAILS: {
      const packageDetailsFilter = state.packageDetails.filter(item => item.PackageId !== action.packageDetails[0].PackageId);

      return {
        ...state,
        packageDetails: [
          ...packageDetailsFilter,
          ...action.packageDetails,
        ],
      };
    }

    case ACTIONS.COMPANY.LOADPACKAGECHANGES: {
      const packageChangesFilter = state.packageChanges.filter(item => item.PackageId !== action.packageChanges[0].PackageId);

      return {
        ...state,
        packageChanges: [
          ...packageChangesFilter,
          ...action.packageChanges,
        ],
      };
    }

    case ACTIONS.COMPANY.LOADPACKAGEDOCUMENTS: {
      return {
        ...state,
        documentsCorrection: action.documentsCorrection,
        loadingButton: false,
      };
    }

    case ACTIONS.COMPANY.CHANGEDATE: {
      return {
        ...state,
        dates: {
          ...state.dates,
          [action.field]: action.value,
        },
      };
    }

    case ACTIONS.COMPANY.UPDATEDETAILS: {
      const newState = { ...state };
      const { details } = action;

      newState.profile.details = {
        ...details,
        Contract: {
          Number: details.Contract ? details.Contract.Number : '',
          StartDate: details.Contract ? details.Contract.StartDate : '',
        },
      };
      newState.accounting.details = details;
      newState.documents.details = details;

      return newState;
    }

    case ACTIONS.COMPANY.LOADINTEGRATION: {
      return {
        ...state,
        accounting: {
          ...state.accounting,
          integration: action.payload,
        },
      };
    }

    case ACTIONS.COMPANY.ERROR_EXPENSE_REPORT: {
      return {
        ...state,
        accounting: {
          ...state.accounting,
          errorExpenseReportDialog: action.payload,
        },
      };
    }

    case ACTIONS.COMPANY.UPDATEOVERDRAFT: {
      return {
        ...state,
        profile: {
          ...state.profile,
          details: {
            ...state.profile.details,
            Overdraft: action.overdraft,
            OverdraftPeriodInDays: action.period,
            AdditionalOverdraft: action.additionalOverdraft,
            AdditionalOverdraftPeriodInDays: action.additionalPeriod,
          },
        },
      };
    }

    case ACTIONS.COMPANY.UPDATEA1CFF: {
      const newState = { ...state };

      newState.applications1CFF = action.applications1CFF;

      return newState;
    }

    case ACTIONS.COMPANY.UPDATEUSERS: {
      const newState = { ...state };

      newState.profile.users = action.users;

      return newState;
    }

    case ACTIONS.COMPANY.LOADING_COMPANY: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    case ACTIONS.COMPANY.UPDATE_REQUISITES: {
      const newState = { ...state };

      newState.requisites = { ...action.payload };

      return newState;
    }

    case ACTIONS.COMPANY.LOADING_SAVE_AVIA: {
      return {
        ...state,
        aviaContractData: {
          ...state.aviaContractData,
          loading: action.loading,
        },
      };
    }

    case ACTIONS.COMPANY.UPDATEAVIACONTRACTS: {
      const newState = { ...state };
      const preparedContract = {
        ...action.res,
        IsDiscount: Number(action.res.IsDiscount),
        Iata: action.res.Iata ? nameIata(action.res) : '',
        Number: preparedAviaCodeNumber(action.res),
      };
      const index = foundIataIndex(newState.profile.aviaContracts, preparedContract.Iata);

      if (index !== -1) {
        newState.profile.aviaContracts[index] = preparedContract;
        newState.aviaContractData.showAviaContractDialog = action.showAviaContractDialog;
        newState.aviaContractData.validation = DEFAULT.aviaContractData.validation;
      } else {
        newState.profile.aviaContracts.push(preparedContract);
        newState.aviaContractData.showAviaContractDialog = action.showAviaContractDialog;
        newState.aviaContractData.validation = DEFAULT.aviaContractData.validation;
      }

      return newState;
    }

    case ACTIONS.COMPANY.CLOSE_ERROR_AVIA_CONTRACT: {
      return {
        ...state,
        aviaContractData: {
          ...state.aviaContractData,
          showErrorDeleteAviaContractDialog: action.showErrorDeleteAviaContractDialog,
          showErrorSaveAviaContractDialog: action.showErrorSaveAviaContractDialog,
        },
      };
    }

    case ACTIONS.COMPANY.LOADINSURENCE: {
      if (action.insuranceData) {
        const newState = { ...state };
        newState.insuranceData.ActiveInsurance = action.insuranceData.ActiveInsurance || {};
        newState.insuranceData.HistoryInsurances = action.insuranceData.HistoryInsurances;

        return newState;
      }

      break;
    }

    case ACTIONS.COMPANY.SAVEINSURENCE: {
      if (action.insuranceData) {
        const newState = { ...state };
        newState.insuranceData = action.insuranceData;

        const { isInsured, CompanyId, StartDate, EndDate } = action.insuranceData;
        newState.insuranceData.startDate = preparedDate(StartDate);
        newState.insuranceData.endDate = preparedDate(EndDate);
        newState.insuranceData.isInsured = !!CompanyId || isInsured;
        newState.insuranceData.alreadyCreatedInsurance = !!CompanyId;

        return newState;
      }

      break;
    }

    case ACTIONS.COMPANY.UPDATEAVIACONTRACTSDELETE: {
      const newState = { ...state };
      const index = foundIataIndex(newState.profile.aviaContracts, action.aviaContract.Iata);

      if (index !== -1) {
        newState.profile.aviaContracts = [
          ...newState.profile.aviaContracts.slice(0, index),
          ...newState.profile.aviaContracts.slice(index + 1),
        ];
      }

      return newState;
    }

    case ACTIONS.COMPANY.DELETE_AVIA_CONTRACT_FAIL: {
      return {
        ...state,
        aviaContractData: {
          ...state.aviaContractData,
          showErrorDeleteAviaContractDialog: true,
        },
      };
    }

    case ACTIONS.COMPANY.SAVE_AVIA_CONTRACT_FAIL: {
      return {
        ...state,
        aviaContractData: {
          ...state.aviaContractData,
          showErrorSaveAviaContractDialog: true,
        },
      };
    }

    case ACTIONS.COMPANY.ADD_AVIA_CONTRACT: {
      return {
        ...state,
        aviaContractData: {
          ...state.aviaContractData,
          showAviaContractDialog: !state.aviaContractData.showAviaContractDialog,
          activeAviaContract: defaultActiveAviaContract(),
          validation: {
            ...DEFAULT.aviaContractData.validation,
          },
        },
      };
    }

    case ACTIONS.COMPANY.UPDATE_AVIA_CONTRACT_DATA: {
      return {
        ...state,
        aviaContractData: action.updatedAviaContractData,
      };
    }


    case ACTIONS.COMPANY.SHOW_AVIA_CONTRACT: {
      return {
        ...state,
        aviaContractData: {
          ...state.aviaContractData,
          showAviaContractDialog: !state.aviaContractData.showAviaContractDialog,
          activeAviaContract: {
            ...action.res,
          },
        },
      };
    }


    case ACTIONS.COMPANY.CLOSE_AVIA_CONTRACT: {
      return {
        ...state,
        aviaContractData: {
          ...state.aviaContractData,
          showAviaContractDialog: !state.aviaContractData.showAviaContractDialog,
          validation: DEFAULT.aviaContractData.validation,
        },
      };
    }

    case ACTIONS.COMPANY.UPDATEFINANCEPAGINATION: {
      return {
        ...state,
        finance: {
          ...state.finance,
          pagination: action.pagination,
        },
      };
    }

    case ACTIONS.COMPANY.UPDATE_FINANCE_PAGINATION_ONLY_PLAN_FEE: {
      return {
        ...state,
        finance: {
          ...state.finance,
          IsPlanFee: action.IsPlanFee,
          filterTransactions: action.filterTransactions,
          pagination: action.pagination,
        },
      };
    }

    case ACTIONS.COMPANY.UPDATE_FINANCE_PAGINATION_ONLY_BANK_PAYMENT: {
      return {
        ...state,
        finance: {
          ...state.finance,
          IsBankPayment: action.IsBankPayment,
          filterTransactions: action.filterTransactions,
          pagination: action.pagination,
        },
      };
    }

    case ACTIONS.COMPANY.UPDATE_FINANCE_PAGINATION_ONLY_CARD_PAYMENT_OR_TRANSACTION_FEE: {
      return {
        ...state,
        finance: {
          ...state.finance,
          IsCardPaymentOrTransactionFee: action.IsCardPaymentOrTransactionFee,
          filterTransactions: action.filterTransactions,
          pagination: action.pagination,
        },
      };
    }

    case ACTIONS.COMPANY.RESET_FILTERS: {
      return {
        ...state,
        finance: {
          ...state.finance,
          filterTransactions: action.filterTransactions,
          IsPlanFee: action.IsPlanFee,
          IsBankPayment: action.IsBankPayment,
          IsCardPaymentOrTransactionFee: action.IsCardPaymentOrTransactionFee,
          pagination: action.pagination,
        },
      };
    }

    case ACTIONS.COMPANY.UPDATEFINANCEVALIDATION: {
      return {
        ...state,
        finance: {
          ...state.finance,
          validation: action.validation,
        },
      };
    }

    case ACTIONS.COMPANY.UPDATEFINANCECOMPONENT: {
      return {
        ...state,
        finance: {
          ...state.finance,
          [action.component]: action.state,
        },
      };
    }

    case ACTIONS.COMPANY.UPDATEACCOUNTING: {
      const newState = { ...state };

      newState.accounting.reports = action.payload;
      newState.accounting.balance = action.payload.reduce((acc, item) => ({
        Credit: acc.Credit + item.Debit, // yes, Credit it's Debit...
        Debit: acc.Debit + item.Credit,  // white it's black, life it's death
      }), { Credit: 0, Debit: 0 });

      return newState;
    }

    case ACTIONS.COMPANY.UPDATEPERIODUPD: {
      return {
        ...state,
        accounting: {
          ...state.accounting,
          periodUpd: action.payload,
        },
      };
    }

    case ACTIONS.COMPANY.UPDATEPERIODEDO: {
      return {
        ...state,
        accounting: {
          ...state.accounting,
          periodEdo: action.payload.periodEdo,
          edo: action.payload.edo,
        },
      };
    }

    case ACTIONS.COMPANY.UPDATESENDINGTYPE: {
      return {
        ...state,
        accounting: {
          ...state.accounting,
          details: {
            ...state.accounting.details,
            SendingType: action.payload,
          },
        },
      };
    }

    case ACTIONS.COMPANY.UPDATESCHEMAUPD: {
      return {
        ...state,
        accounting: {
          ...state.accounting,
          details: {
            ...state.accounting.details,
            Schema: action.payload,
          },
        },
      };
    }

    case ACTIONS.COMPANY.UPDATELOWBALANCE: {
      return {
        ...state,
        accounting: {
          ...state.accounting,
          details: {
            ...state.accounting.details,
            Notification: {
              LowBalance: action.payload,
            },
          },
        },
      };
    }

    case ACTIONS.COMPANY.UPDATESELECTEDPACKAGES: {
      return {
        ...state,
        documents: {
          ...state.documents,
          selectedPackages: action.selectedPackages,
        },
      };
    }

    case ACTIONS.COMPANY.FORMEDREVISEFLAG: {
      const formedRevise = action.formedRevise;
      return {
        ...state,
        formedRevise,
      };
    }

    case ACTIONS.COMPANY.UPDATEDOCUMENTSPAGINATION: {
      return {
        ...state,
        documents: {
          ...state.documents,
          pagination: action.pagination,
        },
      };
    }

    case ACTIONS.COMPANY.RESETFINANCE: {
      return {
        ...state,
        finance: DEFAULT.finance,
      };
    }

    case ACTIONS.COMPANY.RESETFINANCEVALIDATION: {
      return {
        ...state,
        finance: {
          ...state.finance,
          validation: DEFAULT.finance.validation,
        },
      };
    }

    case ACTIONS.COMPANY.RESETDEBITORCREDITDIALOGSTATE: {
      return {
        ...state,
        finance: {
          ...state.finance,
          debitOrCreditDialogState: {
            ...DEFAULT.finance.debitOrCreditDialogState,
          },
        },
      };
    }

    case ACTIONS.COMPANY.RESETDOCUMENTS: {
      return {
        ...DEFAULT,
        documents: DEFAULT.documents,
      };
    }

    case ACTIONS.COMPANY.DEBTSRESET: {
      return {
        ...state,
        debts: DEFAULT.debts,
        insuranceData: DEFAULT.insuranceData,
      };
    }

    case ACTIONS.COMPANY.DEBTSSTARTLOADING: {
      return {
        ...state,
        debts: {
          ...state.debts,
          isLoading: true,
        },
      };
    }

    case ACTIONS.COMPANY.DEBTSLOAD: {
      return {
        ...state,
        debts: {
          data: action.payload,
          isLoading: false,
        },
      };
    }

    case ACTIONS.COMPANY.DEBTSERROR: {
      return {
        ...state,
        debts: {
          ...state.debts,
          isLoading: false,
        },
      };
    }

    case ACTIONS.COMPANY.GET_COMPANY_DEBT: {
      return {
        ...state,
        companyDebt: action.payload,
      };
    }

    case ACTIONS.COMPANY.EXPENSE_REPORTS_LOADING: {
      return {
        ...state,
        expenseReports: {
          ...state.expenseReports,
          isLoading: true,
        },
      };
    }

    case ACTIONS.COMPANY.EXPENSE_REPORTS_UPDATE: {
      return {
        ...state,
        expenseReports: {
          data: action.payload,
          isLoading: false,
          deleteItemLoading: false,
        },
      };
    }

    case ACTIONS.COMPANY.EXPENSE_REPORTS_ERROR: {
      return {
        ...state,
        expenseReports: {
          ...state.expenseReports,
          isLoading: false,
          deleteItemLoading: false,
        },
      };
    }

    case ACTIONS.COMPANY.EXPENSE_REPORTS_RESET: {
      return {
        ...state,
        expenseReports: DEFAULT.expenseReports,
      };
    }

    case ACTIONS.COMPANY.EXPENSE_REPORTS_DELETE_ITEM_LOADING: {
      return {
        ...state,
        expenseReports: {
          ...state.expenseReports,
          deleteItemLoading: !state.expenseReports.deleteItemLoading,
        },
      };
    }

    case ACTIONS.COMPANY.LOADLIMITAMOUNT: {
      const amount = action.limitAmount;

      const limitAmount = {
        enabled: !!amount || amount === 0,
        amount,
      };

      return {
        ...state,
        accounting: {
          ...state.accounting,
          limitAmount,
        },
      };
    }

    case ACTIONS.COMPANY.CLEARLIMITAMOUNT: {
      return {
        ...state,
        accounting: {
          ...state.accounting,
          limitAmount: DEFAULTLIMITAMOUNT,
        },
      };
    }

    case ACTIONS.COMPANY.UPDATEUSERCOMMENT: {
      const users = state.profile.users;
      const foundIndex = users.findIndex(({ Id }) => Id === action.payload.userId);

      if (foundIndex >= 0) {
        users[foundIndex].IntercomComment = action.payload.comment;
      }

      return {
        ...state,
        profile: {
          ...state.profile,
          users,
        },
      };
    }

    case ACTIONS.COMPANY.SEND_DIADOC: {
      return {
        ...state,
        documents: {
          ...state.documents,
          requests: {
            requestDiadoc: action.requestDiadoc,
          },
        },
      };
    }

    case ACTIONS.COMPANY.UPDATE_HOTEL_CONTRACT_DATA: {
      return {
        ...state,
        hotelContractData: action.updatedHotelContractData,
      };
    }

    case ACTIONS.COMPANY.ADD_HOTEL_CONTRACT: {
      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          showHotelContractDialog: !state.hotelContractData.showHotelContractDialog,
          activeHotelContract: defaultActiveHotelContract(),
          validation: {
            ...DEFAULT.hotelContractData.validation,
            Rates: state.hotelContractData.validation.Rates,
          },
          hotelNameAutocomplete: defaultHotelAutocomplete(),
        },
      };
    }

    case ACTIONS.COMPANY.SHOW_HOTEL_CONTRACT: {
      const { hotelContractData: { validation: { Rates } } } = state;

      if (Rates.length !== action.res.Rates.length) {
        action.res.Rates.forEach(() => Rates.push(createNewValidationRate()));
      }

      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          showHotelContractDialog: !state.hotelContractData.showHotelContractDialog,
          activeHotelContract: {
            ...action.res,
            isTravelline: !!action.res.IsTravelline,
          },
          validation: {
            ...DEFAULT.hotelContractData.validation,
            Rates: state.hotelContractData.validation.Rates,
          },
        },
      };
    }

    case ACTIONS.COMPANY.SHOW_HOTEL_CONTRACT_FAIL: {
      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          showErrorHotelContractDialog: true,
        },
      };
    }

    case ACTIONS.COMPANY.ADD_PERIOD_RATE: {
      const { index } = action;
      const { hotelContractData: { activeHotelContract: { Rates } } } = state;

      const newRates = Rates.map((item, ind) => {
        if (ind === index) {
          return {
            ...item,
            Periods: [...item.Periods, deafultPeriodRate()],
          };
        }

        return item;
      });

      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          activeHotelContract: {
            ...state.hotelContractData.activeHotelContract,
            Rates: newRates,
          },
        },
      };
    }

    case ACTIONS.COMPANY.DELETE_PERIOD_RATE: {
      const { indRate, indPeriod } = action.payload;
      const { hotelContractData: { activeHotelContract: { Rates } } } = state;

      const newRates = Rates.map((item, ind) => {
        if (ind === indRate) {
          const filteredPeriod = item.Periods
            .filter((_, iPeriod) => iPeriod !== indPeriod);

          return {
            ...item,
            Periods: filteredPeriod,
          };
        }

        return item;
      });

      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          activeHotelContract: {
            ...state.hotelContractData.activeHotelContract,
            Rates: newRates,
          },
        },
      };
    }

    case ACTIONS.COMPANY.CHANGE_PERIOD_RATE: {
      const { field, value, indRate, indPeriod } = action.payload;
      const { hotelContractData: { activeHotelContract: { Rates } } } = state;

      const newRates = Rates.map((rate, iRate) => {
        if (iRate === indRate) {
          return {
            ...rate,
            Periods: rate.Periods.map((period, iPeriod) => {
              if (iPeriod === indPeriod) {
                return {
                  ...period,
                  [field]: value,
                };
              }

              return period;
            }),
          };
        }

        return rate;
      });

      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          activeHotelContract: {
            ...state.hotelContractData.activeHotelContract,
            Rates: newRates,
          },
        },
      };
    }

    case ACTIONS.COMPANY.GET_RATES_AANDA: {
      const { rates: { ProductInfo } } = action;

      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          ratesAanda: ProductInfo ? ProductInfo.map(({ Code, RoomName }) => ({
            value: Code,
            label: RoomName,
          })) : [],
        },
      };
    }

    case ACTIONS.COMPANY.DELETE_RATES_AANDA: {
      const rates = [...state.hotelContractData.activeHotelContract.Rates];
      const mappedRates = rates.map(item => ({ ...item, Compares: [] }));

      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          activeHotelContract: {
            ...state.hotelContractData.activeHotelContract,
            Rates: mappedRates,
          },
        },
      };
    }

    case ACTIONS.COMPANY.CLOSE_HOTEL_CONTRACT: {
      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          showHotelContractDialog: !state.hotelContractData.showHotelContractDialog,
          validation: DEFAULT.hotelContractData.validation,
        },
      };
    }

    case ACTIONS.COMPANY.SAVE_CONTRACT: {
      const { profile } = state;
      const { hotelContracts } = profile;
      const { res, showHotelContractDialog } = action;
      const filterHotels = hotelContracts.filter(({ Id }) => Id === res.Id);
      const updateHotels = filterHotels.length ? hotelContracts : [...hotelContracts, res];
      const mappedHotels = updateHotels.map(item => (item.Id === res.Id ? res : item));

      return {
        ...state,
        profile: {
          ...profile,
          hotelContracts: mappedHotels,
        },
        hotelContractData: {
          ...state.hotelContractData,
          validation: createDefaultValidation(),
          showHotelContractDialog,
        },
      };
    }

    case ACTIONS.COMPANY.CHECK_BOOKING: {
      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          activeHotelContract: {
            ...state.hotelContractData.activeHotelContract,
            BookingId: action.res.BookingId,
          },
        },
      };
    }

    case ACTIONS.COMPANY.CHECK_BOOKING_FAIL: {
      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          activeHotelContract: {
            ...state.hotelContractData.activeHotelContract,
            BookingId: null,
          },
        },
      };
    }

    case ACTIONS.COMPANY.DELETE_CONTRACT: {
      const newState = { ...state };
      const index = state.profile.hotelContracts.findIndex(({ Id }) => Id === action.contractId);

      if (index !== -1) {
        newState.profile.hotelContracts = [
          ...newState.profile.hotelContracts.slice(0, index),
          ...newState.profile.hotelContracts.slice(index + 1),
        ];
        newState.hotelContractData.showHotelContractDialog = action.showHotelContractDialog;
      }

      return newState;
    }

    case ACTIONS.COMPANY.SAVE_CONTRACT_FAIL: {
      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          showHotelContractDialog: action.showHotelContractDialog,
          showErrorDialog: true,
        },
      };
    }

    case ACTIONS.COMPANY.UPDATE_RATES: {
      const rates = [...state.hotelContractData.activeHotelContract.Rates];
      const { index, field, value } = action;

      const mappedRates = rates.map((item, ind) => {
        const isCurrentIndex = ind === index;
        const isChangedItem = isCurrentIndex && (action.field === FIELDS.IMAGES || action.field === FIELDS.BOOKING_ROOM_NAMES);

        const newItem = isChangedItem ? [...item[field], value] : item[field];
        const newRate = isCurrentIndex && action.field === FIELDS.COMPARE ? value : newItem;

        return {
          ...item,
          [field]: newRate,
        };
      });

      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          activeHotelContract: {
            ...state.hotelContractData.activeHotelContract,
            Rates: mappedRates,
          },
        },
      };
    }

    case ACTIONS.COMPANY.REMOVE_RATES: {
      const rates = [...state.hotelContractData.activeHotelContract.Rates];
      const { index, field, value } = action;

      const mappedRates = rates.map((item, ind) => {
        const isChangedItem = ind === index && (field === FIELDS.IMAGES || field === FIELDS.BOOKING_ROOM_NAMES);

        return {
          ...item,
          [field]: isChangedItem ? item[field].filter(i => i !== value) : item[field],
        };
      });

      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          activeHotelContract: {
            ...state.hotelContractData.activeHotelContract,
            Rates: mappedRates,
          },
        },
      };
    }

    case ACTIONS.COMPANY.CLOSE_ERROR: {
      return {
        ...state,
        profile: DEFAULT.profile,
        hotelContractData: {
          ...state.hotelContractData,
          showHotelContractDialog: action.showHotelContractDialog,
          showErrorDialog: action.showErrorDialog,
          showErrorHotelContractDialog: action.showErrorHotelContractDialog,
          roomTypeIds: state.hotelContractData.roomTypeIds,
        },
      };
    }

    case ACTIONS.COMPANY.ADD_RATE: {
      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          activeHotelContract: {
            ...state.hotelContractData.activeHotelContract,
            Rates: [...state.hotelContractData.activeHotelContract.Rates, createNewRate()],
          },
          validation: {
            ...state.hotelContractData.validation,
            Rates: [...state.hotelContractData.validation.Rates, createNewValidationRate()],
          },
        },
      };
    }

    case ACTIONS.COMPANY.COPY_RATE: {
      const { index } = action;
      const dataRate = state.hotelContractData.activeHotelContract.Rates[index];
      const newDataRatePeriods = {
        ...dataRate,
        Periods: dataRate.Periods.map(period => ({ ...period, Id: 0 })),
      };

      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          activeHotelContract: {
            ...state.hotelContractData.activeHotelContract,
            Rates: [...state.hotelContractData.activeHotelContract.Rates, newDataRatePeriods],
          },
          validation: {
            ...state.hotelContractData.validation,
            Rates: [...state.hotelContractData.validation.Rates, createNewValidationRate()],
          },
        },
      };
    }

    case ACTIONS.COMPANY.DELETE_RATE: {
      const { index } = action;
      const rates = [...state.hotelContractData.activeHotelContract.Rates];
      const validationRates = [...state.hotelContractData.validation.Rates];
      let newValidationRates = [];

      newValidationRates = [
        ...validationRates.slice(0, index),
        ...validationRates.slice(index + 1),
      ];

      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          activeHotelContract: {
            ...state.hotelContractData.activeHotelContract,
            Rates: [
              ...rates.slice(0, index),
              ...rates.slice(index + 1),
            ],
          },
          validation: {
            ...state.hotelContractData.validation,
            Rates: newValidationRates.length !== 0 ? newValidationRates : validationRates,
          },
        },
      };
    }

    case ACTIONS.COMPANY.LOADING_SAVE: {
      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          loading: action.loading,
        },
      };
    }

    case ACTIONS.COMPANY.LOAD_REQUISITES: {
      return {
        ...state,
        requisites: action.payload || DEFAULT.requisites,
      };
    }

    case ACTIONS.COMPANY.LOADING_USER_SETTINGS: {
      return {
        ...state,
        userSettings: action.payload,
      };
    }

    case ACTIONS.COMPANY.LOADING_USER_COMPANIES: {
      return {
        ...state,
        userCompanies: action.payload,
      };
    }

    case ACTIONS.COMPANY.CLEAR_USER_SETTINGS: {
      return {
        ...state,
        userSettings: DEFAULT.userSettings,
      };
    }

    case ACTIONS.COMPANY.LOADING_COMPANY_SETTINGS: {
      return {
        ...state,
        prohibitionVouchers: action.payload,
      };
    }

    case ACTIONS.COMPANY.UPLOAD_HOTEL_CONTRACT: {
      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          activeHotelContract: {
            ...state.hotelContractData.activeHotelContract,
            FileId: action.res.FileId,
          },
          uploadedFile: true,
          failed: false,
          processing: false,
        },
      };
    }

    case ACTIONS.COMPANY.UPLOAD_HOTEL_CONTRACT_FAIL: {
      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          uploadedFile: false,
          failed: true,
          processing: false,
          error: INVALID_CONTRACT.FILE_UPLOAD_FAILED,
        },
      };
    }

    case ACTIONS.COMPANY.GET_ROOM_TYPE: {
      const mappedRoomTypes = action.res.map(({ Id, Name }) => ({
        name: Name,
        cost: Id,
      }));

      return {
        ...state,
        hotelContractData: {
          ...state.hotelContractData,
          roomTypeIds: mappedRoomTypes,
        },
      };
    }

    case ACTIONS.COMPANY.LOADING_USERS: {
      return {
        ...state,
        userActiveData: {
          ...state.userActiveData,
          loading: action.loading,
        },
      };
    }

    case ACTIONS.COMPANY.SHOW_EDIT_USER_DIALOG: {
      return {
        ...state,
        userActiveData: {
          ...state.userActiveData,
          showEditUserDialog: !state.userActiveData.showEditUserDialog,
          ...action.item,
        },
      };
    }

    case ACTIONS.COMPANY.SHOW_USER_RIGHTS_DIALOG: {
      return {
        ...state,
        userActiveData: {
          ...state.userActiveData,
          showUserRightsDialog: !state.userActiveData.showUserRightsDialog,
          showUserApproveDialog: DEFAULT.userActiveData.showUserApproveDialog,
          ...action.item,
        },
      };
    }

    case ACTIONS.COMPANY.SHOW_NEXT_STEP_DISABLE_OF_RIGHTS_DIALOG: {
      return {
        ...state,
        userActiveData: {
          ...state.userActiveData,
          showUserRightsDialog: !state.userActiveData.showUserRightsDialog,
          showNextStepDisableOfRightsDialog: !state.userActiveData.showNextStepDisableOfRightsDialog,
          ...action.item,
        },
      };
    }

    case ACTIONS.COMPANY.GET_USER_APPROVE_SCHEME: {
      const { ByDirect, ByScheme } = action.res;
      const checkUser = ByDirect || ByScheme ?
        FIELDS.USER_ACTIVE_DATA.SHOW_USER_APPROVE_DIALOG : FIELDS.USER_ACTIVE_DATA.SHOW_USER_RIGHTS_DIALOG;

      return {
        ...state,
        userActiveData: {
          ...state.userActiveData,
          userApproveScheme: action.res,
          [checkUser]: true,
          ...action.item,
        },
      };
    }

    case ACTIONS.COMPANY.USER_SET_RIGHTS: {
      const users = state.profile.users;
      const foundIndex = users.findIndex(({ Id }) => Id === action.res.Id);

      if (foundIndex >= 0) {
        users[foundIndex] = action.res;
      }

      return {
        ...state,
        profile: {
          ...state.profile,
          users,
        },
        userActiveData: {
          ...state.userActiveData,
          showUserRightsDialog: DEFAULT.userActiveData.showUserRightsDialog,
          showNextStepDisableOfRightsDialog: DEFAULT.userActiveData.showNextStepDisableOfRightsDialog,
        },
      };
    }

    case ACTIONS.COMPANY.USER_RIGHT_BUTTONS: {
      const loadingButtons = { loading: action.loading, status: action.status };

      return {
        ...state,
        userActiveData: {
          ...state.userActiveData,
          loadingButtons,
        },
      };
    }

    case ACTIONS.COMPANY.SHOW_CANCEL_OF_RIGHTS_DIALOG: {
      return {
        ...state,
        userActiveData: {
          ...state.userActiveData,
          showUserRightsDialog: DEFAULT.userActiveData.showUserRightsDialog,
          showNextStepDisableOfRightsDialog: DEFAULT.userActiveData.showNextStepDisableOfRightsDialog,
          showUserApproveDialog: DEFAULT.userActiveData.showUserApproveDialog,
        },
      };
    }

    case ACTIONS.COMPANY.SAVE_ERROR_SET_RIGHTS: {
      return {
        ...state,
        userActiveData: {
          ...state.userActiveData,
          saveErrorSetRightUser: DEFAULT.userActiveData.saveErrorSetRightUser,
          sendUserDocFail: DEFAULT.userActiveData.sendUserDocFail,
          ...action.item,
        },
      };
    }

    case ACTIONS.COMPANY.WAITING_SEND_USER_DOC: {
      return {
        ...state,
        userActiveData: {
          ...state.userActiveData,
          waitingSendUserDoc: !state.userActiveData.waitingSendUserDoc,
        },
      };
    }

    case ACTIONS.COMPANY.SAVE_USER_DATA: {
      const users = state.profile.users;
      const foundIndex = users.findIndex(({ Id }) => Id === action.res.Id);

      if (foundIndex >= 0) {
        users[foundIndex] = action.res;
      }

      return {
        ...state,
        profile: {
          ...state.profile,
          users,
        },
        userActiveData: {
          ...state.userActiveData,
          showEditUserDialog: !state.userActiveData.showEditUserDialog,
        },
      };
    }

    case ACTIONS.COMPANY.SEND_USER_DOC: {
      const users = state.profile.users;
      const foundIndex = users.findIndex(({ Id }) => Id === action.id);

      if (foundIndex >= 0) {
        users[foundIndex].SendDoc = action.sendDoc;
      }

      return {
        ...state,
        profile: {
          ...state.profile,
          users,
        },
      };
    }

    case ACTIONS.COMPANY.SEND_USER_DOC_FAIL: {
      return {
        ...state,
        userActiveData: {
          ...state.userActiveData,
          sendUserDocFail: !state.userActiveData.sendUserDocFail,
          ...action.item,
        },
      };
    }

    case ACTIONS.COMPANY.UPDATE_DELETE_DIALOG: {
      return {
        ...state,
        userActiveData: {
          ...state.userActiveData,
          deleteDialog: {
            ...state.userActiveData.deleteDialog,
            ...action.value,
          },
        },
      };
    }

    case ACTIONS.COMPANY.UPDATE_USER_DATA: {
      return {
        ...state,
        userActiveData: action.updateUserData,
      };
    }

    case ACTIONS.COMPANY.SAVE_USER_DATA_FAIL: {
      return {
        ...state,
        userActiveData: {
          ...state.userActiveData,
          showEditUserDialog: !state.userActiveData.showEditUserDialog,
          showUserErrorDialog: !state.userActiveData.showUserErrorDialog,
        },
      };
    }

    case ACTIONS.COMPANY.GET_SETTING_DISPLAY_DATA: {
      return {
        ...state,
        ShowInDocuments: action.res.ShowInDocuments,
      };
    }

    case ACTIONS.COMPANY.GET_CARD_PAYMENT_FEE: {
      return {
        ...state,
        cardPaymentFee: action.payload,
      };
    }

    case ACTIONS.COMPANY.UPDATE_EMPLOYEE_DOCUMENT_LOADING: {
      return {
        ...state,
        processedFile: {
          ...state.processedFile,
          loading: action.payload,
        },
      };
    }

    case ACTIONS.COMPANY.UPLOAD_FILE: {
      return {
        ...state,
        file: {
          ...state.file,
          File: action.file,
          uploaded: true,
          failed: false,
        },
      };
    }

    case ACTIONS.COMPANY.UPLOAD_FILE_FAIL: {
      return {
        ...state,
        file: {
          uploaded: true,
          failed: true,
          error: action.payload,
          guid: '',
        },
      };
    }

    case ACTIONS.COMPANY.SEND_UPLOAD_EMPLOYEES_FILE: {
      return {
        ...state,
        file: {
          uploaded: true,
          failed: false,
          error: '',
        },
        processedFile: {
          information: action.payload,
          secondPhase: false,
          loadDocument: true,
        },
      };
    }

    case ACTIONS.COMPANY.SEND_BULK_UPLOAD_FILE: {
      return {
        ...state,
        file: {
          uploaded: true,
          failed: false,
          error: '',
        },
        processedFile: {
          information: action.payload,
          secondPhase: false,
          loadDocument: true,
        },
      };
    }

    case ACTIONS.COMPANY.UPLOAD_RIGHTS_EMPLOYEE: {
      return {
        ...state,
        file: {
          uploaded: true,
          failed: false,
          error: '',
        },
        processedFile: {
          information: action.payload,
          secondPhase: false,
          loadDocument: true,
        },
      };
    }

    case ACTIONS.COMPANY.SECOND_PHASE_LOADING: {
      return {
        ...state,
        processedFile: {
          information: action.payload,
          secondPhase: true,
          loadDocument: true,
        },
      };
    }

    case ACTIONS.COMPANY.CLEAR_UPLOADED_FILE: {
      return {
        ...state,
        file: DEFAULT.file,
        processedFile: DEFAULT.processedFile,
      };
    }

    case ACTIONS.COMPANY.LOAD_COMPANY_SETTINGS: {
      return {
        ...state,
        companySettings: action.payload,
      };
    }

    case ACTIONS.COMPANY.SET_LOADING_COMPANY_SETTINGS: {
      return {
        ...state,
        loadingCompanySettings: action.payload,
      };
    }
    case ACTIONS.COMPANY.LOADING_RESPONSE_FOR_APPROVE: {
      return {
        ...state,
        finance: {
          ...state.finance,
          waitigResponseForApprove: action.payload,
        },
      };
    }
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');

  core.addStore('Company', reducer, DEFAULT);
}
