let ACTIONS;

const defaultStore = {
  loading: false,
  list: [],
  providersObject: null,
  schemeSelectList: [],
  schemeSelectedValue: '',
  responseProgress: null,
  responseStatus: null,
  responseSendingProgress: null,
  responseSendingStatus: null,
  responseGeneralActStatus: null,
};

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.BUSINESS.UPDATETRIP: {
      // eslint-disable-next-line no-param-reassign
      state.loading = false;
      // eslint-disable-next-line no-param-reassign
      state.list = action.payload;
      break;
    }

    case ACTIONS.BUSINESS.LOADING_PROVIDERS: {
      return {
        ...state,
        providersObject: {
          ...state.providersObject,
          loading: action.loading,
        },
      };
    }

    case ACTIONS.BUSINESS.SHOW_SETTINGS: {
      const { schemeSelectList, schemeSelectedValue, providersObject, hotelsProvidersObject } = action;

      return {
        ...state,
        schemeSelectList: schemeSelectList.value,
        schemeSelectedValue: schemeSelectedValue.value.Name,
        providersObject: providersObject.value,
        hotelsProvidersObject: hotelsProvidersObject.value,
      };
    }

    case ACTIONS.BUSINESS.PRINT_DOC_STATUS: {
      const { responseProgress, responseStatus } = action;

      return {
        ...state,
        responseProgress,
        responseStatus,
      };
    }

    case ACTIONS.BUSINESS.AUTO_SENDING_EDO: {
      const { responseSendingProgress, responseSendingStatus } = action;

      return {
        ...state,
        responseSendingProgress,
        responseSendingStatus,
      };
    }

    case ACTIONS.BUSINESS.GENERAL_ACT_COMPLETED_WORKS: {
      const { responseGeneralActStatus } = action;

      return {
        ...state,
        responseGeneralActStatus,
      };
    }
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('Business', reducer, defaultStore);
}
