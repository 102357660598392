let ACTIONS;

const DEFAULT = { accounts: [] };

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.STOREINIT:
      return DEFAULT;

    case ACTIONS.COMPANIES.UPDATE:
      return {
        ...state,
        accounts: action.res,
      };

    case ACTIONS.COMPANIES.UPDATEORDERS:
      return action.res;
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('Companies', reducer, DEFAULT);
}
