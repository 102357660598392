let ACTIONS;

const defaultStore = {
  calls: [],
  isRequesting: false,
};

const reducer = ({ type, calls, isRequesting }, state) => {
  switch (type) {
    case ACTIONS.CALLS.INIT:
      return {
        ...state,
        calls,
        isRequesting,
      };
    case ACTIONS.CALLS.UPDATE:
      return {
        ...state,
        calls,
        isRequesting,
      };
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('Calls', reducer, defaultStore);
}
